import React, { Suspense, lazy } from 'react';
import { withRouter, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import E404 from './components/errors/e404';
// import Home from './components/top/home';
// import Navigation from './components/top/navigation';

const Home = lazy(() => import('./components/top/home'));
const Privacy = lazy(() => import('./components/top/privacy'));
const Terms = lazy(() => import('./components/top/terms'));
const Navigation = lazy(() => import('./components/top/navigation'));
const Station = lazy(() => import('./components/top/station'));
const Footer = lazy(() => import('./components/top/footer'));

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className='loader'>
            <div className='lds-dual-ring'></div>
          </div>
        }>
        <Navigation />
        <Switch>
          <Route exact path='/page-not-found' render={(props) => <E404 {...props} />} />

          <Route exact path='/privacyverklaring' render={(props) => <Privacy {...props} />} />
          <Route exact path='/algemene_voorwaarden' render={(props) => <Terms {...props} />} />

          <Route exact path='/station/:station' render={(props) => <Station {...props} />} />
          <Route exact path='/' render={(props) => <Home {...props} />} />

          <Redirect to='/page-not-found' />
        </Switch>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default withRouter(App);
