import React from 'react';
import { Link } from 'react-router-dom';
import { details } from '../../services/business';
import { getLocalizedUrl } from '../../services/languageService';
import { getStations } from '../../services/stations';
import styles from '../../styles/modules/footer.module.scss';
import { NavHashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={`${styles.wrapper} container__fixed-width`}>
        <div className={styles.info_container}>
          <div className={styles.block}>
            <p className={`${styles.block__title} text`}>{details.name}</p>
            <p className={`${styles.block__text} text`}>
              {details.address.street} {details.address.number}
            </p>
            <p className={`${styles.block__text} text`}>
              {details.address.zip} {details.address.city}
            </p>
          </div>
          <div className={styles.block}>
            <a className={`${styles.block__text} text`} href={`tel:${details.phone}`}>
              {details.phone}
            </a>
            <a className={`${styles.block__text} text`} href={`fax:${details.fax}`}>
              {details.fax}
            </a>
            <a className={`${styles.block__text} text`} href={`mailto:${details.mail}`}>
              {details.mail}
            </a>
          </div>
          <div className={styles.block}>
            <p className={`${styles.block__text} text`}>{details.vat}</p>
          </div>
        </div>

        <div className={`${styles.sitemap_container} ${styles.sitemap__small}`}>
          <p className={`${styles.block__title} text`}>Sitemap</p>
          <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#home')}>
            Home
          </NavHashLink>
          <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#about')}>
            Over Ons
          </NavHashLink>
          <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#stations')}>
            Stations
          </NavHashLink>
          <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#contact')}>
            Contact
          </NavHashLink>
        </div>
        <div className={`${styles.sitemap_container} ${styles.sitemap__large}`}>
          <p className={`${styles.block__title} text`}>Sitemap</p>
          <div className={styles.sitemap__large__block}>
            <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#home')}>
              Home
            </NavHashLink>
          </div>
          <div className={styles.sitemap__large__block}>
            <p className={`${styles.block__text} text`}>Over Ons</p>
            <NavHashLink
              smooth
              className={`${styles.block__text} ${styles.block__subtext} text`}
              to={getLocalizedUrl('#about')}>
              Over Ons
            </NavHashLink>
            <NavHashLink
              smooth
              className={`${styles.block__text} ${styles.block__subtext}  text`}
              to={getLocalizedUrl('#benefits')}>
              Voordelen
            </NavHashLink>
          </div>
          <div className={styles.sitemap__large__block}>
            <p className={`${styles.block__text} text`}>Stations</p>
            {getStations().map((station, key) => (
              <Link
                key={key}
                className={`${styles.block__text} ${styles.block__subtext} text`}
                to={getLocalizedUrl(`station/${station.id}`)}>
                {station.name}
              </Link>
            ))}
          </div>
          <div className={styles.sitemap__large__block}>
            <NavHashLink smooth className={`${styles.block__text} text`} to={getLocalizedUrl('#contact')}>
              Contact
            </NavHashLink>
          </div>
        </div>

        <div className={styles.links_container}>
          <div className={styles.block}>
            <p className={`${styles.block__title} text`}>Hanidge Links</p>
            <Link to={getLocalizedUrl('algemene_voorwaarden')}>
              <p className={`${styles.block__text} text`}>Alg. Voorwaarden</p>
            </Link>
            <Link to={getLocalizedUrl('privacyverklaring')}>
              <p className={`${styles.block__text} text`}>Privacyverklaring</p>
            </Link>
          </div>
          <div className={styles.block}></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
