import React from 'react';
import HeaderImage from '../top/header/headerImage';
import { Arrow } from '../images/icons';

import styles from '../../styles/modules/home/header.module.scss';

const Header = React.memo(() => {
  return (
    <header id='home' className={styles.header_home}>
      <HeaderImage
        styles={styles}
        image={{ alt: 'Wapperende vlaggen', ext: 'jpg', size: { width: 1920, height: 600 } }}
        src='header'
      />
      <div className={styles.highlight}>
        <div className={`${styles.highlight_container__red} ${styles.highlight_container}`}>
          {/* <div className={styles.highlight}> */}
          <h2 className='title title__white title__bold'>Wij zoeken flexi-jobs</h2>
          <p className='text text__white'>
            Je springt in bij afwezigheid vaste medewerkers. Je bent gepensioneerd en tweetalig, hebt computerkennis en
            beschikt over eigen vervoer.
          </p>
          <a
            className={`${styles.link} text text__white`}
            href='mailto:evy@kaspers.be'
            // target='_blank'
            rel='noopener noreferrer'>
            Interesse? <Arrow />
          </a>
        </div>
        <div className={`${styles.highlight_container__orange} ${styles.highlight_container}`}>
          {/* <div className={styles.highlight}> */}
          <h2 className='title title__white title__bold'>Gratis Pechverhelping</h2>
          <p className='text text__white'>
            3 weken gratis pechverhelping bij elke tankbeurt van 25 liter* in België en Luxemburg.
          </p>
          <a
            className={`${styles.link} text text__white`}
            href='https://www.total.be/nl/promoties/total-assistance'
            target='_blank'
            rel='noopener noreferrer'>
            Ontdek meer <Arrow />
          </a>
        </div>
      </div>
    </header>
  );
});

export default Header;
