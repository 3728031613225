import { renderToStaticMarkup } from 'react-dom/server';
import globalTranslations from '../translations/global.json';

const languages = [{ name: 'Dutch', code: 'nl' }];

export function initialize(props) {
  const browserLanguage = props.history.location.pathname.substring(1, 3);
  const storedLanguage = localStorage.getItem('languageCode') || languages[0];
  const filteredCheck = languages.filter((lang) => lang.code === browserLanguage);
  const defaultLanguage =
    filteredCheck.length === 1
      ? browserLanguage === storedLanguage
        ? storedLanguage
        : browserLanguage
      : languages[0].code;

  props.initialize({
    languages,
    translation: globalTranslations,
    options: {
      defaultLanguage,
      renderToStaticMarkup,
    },
  });
}

export function checkLanguageUpdate(prevProps, props) {
  const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
  const curLangCode = props.activeLanguage && props.activeLanguage.code;
  const hasLanguageChanged = prevLangCode !== curLangCode;
  if (hasLanguageChanged) {
    curLangCode === 'nl' ? props.history.replace('/') : props.history.replace(`/${curLangCode}`);
    localStorage.setItem('languageCode', curLangCode);
  }
}

export function getLocalizedUrl(url) {
  // const code = localStorage.getItem('languageCode');
  // return code === 'nl' ? `/${url}` : `/${code}/${url}`;
  return `/${url}`;
}

export function translate(errors) {
  Object.keys(errors).map(function (key, index) {
    const err = errors[key];

    if (err.includes('empty')) errors[key] = 'Gelieve dit veld in te vullen.';
    else if (err.includes('valid email')) errors[key] = 'Gelieve een geldig e-mailadres in te vullen.';
    else if (err.includes('valid phonenumber')) errors[key] = 'Gelieve een geldig telefoonnummer in te vullen.';
    else if (err.includes('length')) {
      const num = parseInt(errors[key].replace(/[^\d.]/g, ''));
      errors[key] = `Een minimale lengte van ${num} karakters is vereist.`;
    }
  });

  return errors;
}
