import React, { Component } from 'react';
import innerHeight from 'ios-inner-height';
import LazyBgPicture from '../../common/picture/lazyBgPicture';

class HeaderImage extends Component {
  state = {
    mobile: window.innerWidth < 768,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { image } = this.props;

    const width = window.innerWidth;
    const height = innerHeight() * 0.55;
    const mobile = width < 768;

    const imgWidth = image.size.width;
    const imgHeight = image.size.height;
    const calcWidth = (imgWidth / imgHeight) * height;
    const calcHeight = (imgHeight / imgWidth) * width;

    if (width <= height) {
      // Portrait
      this.setState({ width: `${calcWidth}px`, height: '55vh', mobile });
    } else {
      // Landscape
      if (calcWidth < width) this.setState({ width: '100%', height: `${calcHeight}px`, mobile });
      else this.setState({ width: `${calcWidth}px`, height: '55vh', mobile });
    }
  };

  render() {
    const { styles, image, src, children } = this.props;
    const { width, height, mobile } = this.state;

    return (
      <div className={styles.image} style={{ width: width, height: height }}>
        <LazyBgPicture src={mobile ? `${src}_mobile` : src} ext={image.ext} alt={image.alt} bgImg='header' />
        {children}
      </div>
    );
  }
}

export default HeaderImage;
