import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import './styles/fonts.scss';

require('dotenv').config();

/* IE FIX */
require('intersection-observer');
require('unorm');

/* TOAST */
toast.configure({
  position: 'bottom-right',
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: 'toast-message',
});

/* BROWSER DETECTION */
const { detect } = require('detect-browser');
const browser = detect();
if (browser) {
  console.log('Browser Info', browser);
  document.body.classList.add(browser.name);
}

/* SMOOTH SCROLLING */
smoothscroll.polyfill();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
