import React from 'react';
import styles from '../../styles/modules/spacer.module.scss';

const Spacer = React.memo(() => {
  return (
    <div className={`${styles.spacer} container__fixed-width`}>
      <div className={styles.line}></div>
    </div>
  );
});

export default Spacer;
