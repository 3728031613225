import React from 'react';
import {
  G95E10,
  G98E5,
  Diesel,
  LPG,
  AdBlue,
  Pellets,
  Gas,
  CarWash,
  Coffee,
  Food,
  Sandwiches,
} from '../components/images/icons';

const stations = [
  {
    id: 'landen_station',
    name: 'Landen Station',
    french: false,
    pictures: [{ alt: 'Tankstation' }, { alt: 'Winkel Links' }, { alt: 'Winkel Rechts' }],
    location: { lat: 50.748124, lng: 5.081968 },
    street: 'Postlaan, 4',
    place: 'Landen',
    zip: 'B-3400',
    phone: '+32 477 83 31 97',
    // fax: '+32 11 831 401',
    mail: 'info@stationskaspers.be',
    hours: [
      { days: 'Ma - Vr', hours: '6.00 - 19.00u' },
      { days: 'Zaterdag', hours: '8.00 - 16.00u' },
      { days: 'Zondag', hours: 'Gesloten' },
      { days: 'Feestdagen', hours: 'Gesloten' },
    ],
    fuels: [
      { icon: <G95E10 />, circle: true },
      { icon: <G98E5 />, circle: true },
      { icon: <Diesel />, circle: true },
    ],
    services: [
      { icon: <AdBlue /> },
      { icon: <Pellets /> },
      { icon: <Gas /> },
      { icon: <Coffee /> },
      { icon: <Food /> },
    ],
    cards: {
      bank: ['AMEX', 'Mastercard', 'Eurotraffic', 'Maestro', 'Total', 'Bancontact', 'Visa'],
      tank: ['MOL', 'Total Club', 'Aral'],
      loyalty: ['Total Club'],
    },
  },

  {
    id: 'gingelom',
    name: 'Gingelom',
    french: false,
    pictures: [{ alt: 'Tankstation' }, { alt: 'Winkel Links' }, { alt: 'Winkel Rechts' }],
    location: { lat: 50.749493, lng: 5.13245 },
    street: 'Steenweg, 158',
    place: 'Gingelom',
    zip: 'B-3890',
    phone: '+32 477 83 31 90',
    fax: '+32 11 884 551',
    mail: 'info@stationskaspers.be',
    hours: [
      { days: 'Ma - Vr', hours: '6.00 - 19.00u' },
      { days: 'Zaterdag', hours: '8.00 - 17.00u' },
      { days: 'Zondag', hours: 'Gesloten' },
      { days: 'Feestdagen', hours: 'Gesloten' },
    ],
    fuels: [
      { icon: <G95E10 />, circle: true },
      { icon: <G98E5 />, circle: true },
      { icon: <Diesel />, circle: true },
    ],
    services: [
      { icon: <AdBlue /> },
      { icon: <Pellets /> },
      { icon: <Gas /> },
      { icon: <CarWash /> },
      { icon: <Coffee /> },
      { icon: <Food /> },
    ],
    cards: {
      bank: ['AMEX', 'Mastercard', 'Eurotraffic', 'Maestro', 'Total', 'Bancontact', 'Visa'],
      tank: ['MOL', 'Total Club', 'Fleet Fuel', 'Aral'],
      loyalty: ['Total Club'],
    },
  },

  {
    id: 'hakendover',
    name: 'Hakendover',
    french: false,
    pictures: [{ alt: 'Tankstation' }, { alt: 'Winkel Links' }, { alt: 'Winkel Rechts' }],
    location: { lat: 50.79734, lng: 4.989137 },
    street: 'Sint-Truidensesteenweg, 573',
    place: 'Hakendover',
    zip: 'B-3300',
    phone: '+32 477 83 31 95',
    fax: '+32 16 788 003',
    mail: 'info@stationskaspers.be',
    hours: [
      { days: 'Ma - Vr', hours: '6.00 - 19.00u' },
      { days: 'Zaterdag', hours: '8.00 - 17.00u' },
      { days: 'Zondag', hours: 'Gesloten' },
      { days: 'Feestdagen', hours: 'Gesloten' },
    ],
    fuels: [
      { icon: <G95E10 />, circle: true },
      { icon: <G98E5 />, circle: true },
      { icon: <Diesel />, circle: true },
    ],
    services: [
      { icon: <AdBlue /> },
      { icon: <Pellets /> },
      { icon: <Coffee /> },
      { icon: <Sandwiches /> },
      { icon: <Food /> },
    ],
    cards: {
      bank: ['AMEX', 'DKV', 'Mastercard', 'Eurotraffic', 'Maestro', 'Total', 'Bancontact', 'Visa'],
      tank: ['MOL', 'Total Club', 'Aral'],
      loyalty: ['Total Club'],
    },
  },

  {
    id: 'hannut',
    name: 'Hannut',
    french: true,
    pictures: [{ alt: 'Tankstation' }, { alt: 'Winkel Links' }, { alt: 'Winkel Rechts' }],
    location: { lat: 50.678507, lng: 5.070634 },
    street: 'Chee de Tirlemont, 84',
    place: 'Hannut',
    zip: 'B-4280',
    phone: '+32 477 83 31 83',
    fax: '+32 19 514 141',
    mail: 'info@stationskaspers.be',
    hours: [
      { days: 'Ma - Vr', hours: '6.00 - 19.00u' },
      { days: 'Zaterdag', hours: '8.00 - 17.00u' },
      { days: 'Zondag', hours: 'Gesloten' },
      { days: 'Feestdagen', hours: 'Gesloten' },
    ],
    frenchhours: [
      { days: 'Lun - Ven', hours: '6h00 - 19h00' },
      { days: 'Sam', hours: '8h00 - 17h00' },
      { days: 'Dim', hours: 'Fermé' },
      { days: 'Jours Fériés', hours: 'Fermé' },
    ],
    fuels: [
      { icon: <G95E10 />, circle: true },
      { icon: <G98E5 />, circle: true },
      { icon: <Diesel />, circle: true },
    ],
    services: [
      { icon: <AdBlue /> },
      { icon: <Pellets /> },
      { icon: <Gas /> },
      { icon: <CarWash /> },
      { icon: <Coffee /> },
      { icon: <Food /> },
    ],
    cards: {
      bank: ['AMEX', 'Eurotraffic', 'Total', 'Bancontact'],
      tank: ['MOL', 'Total Club', 'Aral', 'Fleet Fuel'],
      loyalty: ['Total Club'],
    },
  },

  {
    id: 'braives',
    name: 'Braives',
    french: true,
    pictures: [{ alt: 'Tankstation' }, { alt: 'Winkel Links' }, { alt: 'Winkel Rechts' }],
    location: { lat: 50.637119, lng: 5.153832 },
    street: 'Chaussée de Tirlemont, 7',
    place: 'Braives',
    zip: 'B-4260',
    phone: '+32 477 83 31 75',
    fax: '+32 19 699 158',
    mail: 'info@stationskaspers.be',
    hours: [
      { days: 'Ma - Vrij', hours: '6.00 - 19.00u' },
      { days: 'Zat', hours: '7.30 - 16.30u' },
      { days: 'Zon', hours: 'Gesloten' },
      { days: 'Feestdagen', hours: 'Gesloten' },
    ],
    frenchhours: [
      { days: 'Lun - Ven', hours: '6h00 - 19h00' },
      { days: 'Sam', hours: '7h30 - 16h30' },
      { days: 'Dim', hours: 'Fermé' },
      { days: 'Jours Fériés', hours: 'Fermé' },
    ],
    fuels: [
      { icon: <G95E10 />, circle: true },
      { icon: <G98E5 />, circle: true },
      { icon: <Diesel />, circle: true },
      { icon: <LPG />, circle: false },
    ],
    services: [
      { icon: <AdBlue /> },
      { icon: <Pellets /> },
      { icon: <Gas /> },
      { icon: <Coffee /> },
      { icon: <Food /> },
    ],
    cards: {
      bank: ['AMEX', 'Eurotraffic', 'Total', 'Bancontact'],
      tank: ['MOL', 'Total Club', 'Fleet Fuel', 'Aral'],
      loyalty: ['Total Club'],
    },
  },
];

export const getStations = () => {
  return stations;
};
