import React from 'react';
import styles from '../../styles/modules/legal.module.scss';

const ErrorText = React.memo(() => {
  return (
    <section className={styles.legal} id='privacy'>
      <div className={`${styles.wrapper} container__fixed-width`}>
        <div className={styles.title_container}>
          <h2 className='title title__gray'>
            Deze <span className='title title__red title__bold'>pagina</span> kan <br />
            niet worden gevonden
          </h2>
        </div>
        <div className={`${styles.text_container} ${styles.text_container__red}`}>
          <p className='text text__gray'>
            Helaas kunnen we de pagina die u zoekt niet vinden. Dit komt doordat de pagina niet meer bestaat, of doordat
            een typfout is gemaakt bij het invoeren van het adres.
          </p>
          <br />
          <br />
          <p className='text text__gray'>U kunt het via de navigatie opnieuw proberen.</p>
        </div>
      </div>
    </section>
  );
});

export default ErrorText;
