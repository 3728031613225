import React, { Component } from 'react';
import styles from './lazypicture.module.scss';

class LazyBgPicture extends Component {
  state = {
    srcBlank: true,
    imageLoaded: false,
  };

  componentDidMount() {
    this.setState({ srcBlank: false });
  }

  exists(img) {
    try {
      return this.state.srcBlank ? undefined : require(`../../../images/${img}`);
    } catch {
      return undefined;
    }
  }

  handleLoad = () => {
    this.setState({ imageLoaded: true });
  };

  render() {
    const { src, ext, alt, extraClass, bgImg, ...rest } = this.props;
    const { imageLoaded } = this.state;

    const webp = this.exists(`${src}.webp`);
    const webp2x = this.exists(`${src}@2x.webp`);
    const std = this.exists(`${src}.${ext}`);
    const std2x = this.exists(`${src}@2x.${ext}`);

    return (
      <div className={`${styles.container} ${imageLoaded && styles.container_loaded}`}>
        <picture>
          {!(webp && webp2x) || <source type='image/webp' srcSet={`${webp2x} 2x, ${webp} 1x`} />}
          {!(std && std2x) || <source srcSet={`${std2x} 2x, ${std} 1x`} />}
          <img {...rest} src={std} alt={alt} className={`${styles.image}`} onLoad={this.handleLoad} />
        </picture>
        <div className={`${styles.bg} ${bgImg}`}></div>
      </div>
    );
  }
}

export default LazyBgPicture;
