import React from 'react';

export const Logo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 59.41 14.92'>
      <path
        d='M39,7.23c0-1.55-.67-2.33-2-2.33a7.28,7.28,0,0,0-1.5.12V9.53a3.43,3.43,0,0,0,1.75.45C38.4,10,39,9.06,39,7.23Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M27.88,1.61V.51c0-.14-.08-.21-.17-.21s-.18.07-.18.21v1.1c0,.15.08.21.18.21S27.88,1.76,27.88,1.61Z'
        style={{ fill: 'none' }}
      />
      <polygon points='38.3 0.62 38.3 0.62 38.17 1.37 38.43 1.37 38.3 0.62' style={{ fill: 'none' }} />
      <path d='M44.8,4.85c-1,0-1.55.62-1.63,1.86h3.14C46.38,5.47,45.88,4.85,44.8,4.85Z' style={{ fill: 'none' }} />
      <polygon points='16.31 0.62 16.3 0.62 16.17 1.37 16.43 1.37 16.31 0.62' style={{ fill: 'none' }} />
      <path
        d='M21.08,9.07c0,.66.38,1,1.15,1a3,3,0,0,0,2-.68V8.25a8,8,0,0,0-1.76-.17C21.54,8.08,21.08,8.41,21.08,9.07Z'
        style={{ fill: 'none' }}
      />
      <polygon points='23.88 0.62 23.87 0.62 23.74 1.37 24 1.37 23.88 0.62' style={{ fill: 'none' }} />
      <path
        d='M7.19,7.36a2.79,2.79,0,0,0,1.2-.66l0,0a1.83,1.83,0,0,1,.07.32A2.53,2.53,0,0,0,8.41,6l0-.06A2.82,2.82,0,0,0,7.83,5,10.25,10.25,0,0,1,7.19,7.36Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M42.31,1.61V.51c0-.14-.08-.21-.18-.21A.19.19,0,0,0,42,.51v1.1c0,.15.09.21.18.21S42.31,1.76,42.31,1.61Z'
        style={{ fill: 'none' }}
      />
      <path d='M51.42.3h-.14V1h.15c.18,0,.21-.12.21-.33S51.61.3,51.42.3Z' style={{ fill: 'none' }} />
      <polygon
        points='15.12 2.1 15.12 0.3 15.42 0.3 15.42 0.02 14.56 0.02 14.56 0.3 14.86 0.3 14.86 2.1 15.12 2.1'
        style={{ fill: '#fff' }}
      />
      <path
        d='M16.48,1.66l.08.44h.26L16.41,0H16.2L15.79,2.1h.26l.08-.44Zm-.18-1h0l.13.75h-.27Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='17.72 0.85 18.11 2.1 18.36 2.1 18.36 0.02 18.1 0.02 18.1 1.27 18.09 1.27 17.7 0.02 17.45 0.02 17.45 2.1 17.71 2.1 17.71 0.85 17.72 0.85'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='19.11 0.02 19.11 2.1 19.37 2.1 19.37 1.48 19.51 1.18 19.8 2.1 20.08 2.1 19.66 0.85 20.02 0.02 19.75 0.02 19.37 0.97 19.37 0.97 19.37 0.02 19.11 0.02'
        style={{ fill: '#fff' }}
      />
      <path
        d='M20.66,1.52v.11a.46.46,0,0,0,.44.49c.34,0,.45-.26.45-.61s0-.47-.27-.57l-.2-.09c-.14-.06-.15-.12-.15-.28s0-.27.18-.27.18.11.18.25V.62h.26V.47A.45.45,0,0,0,21.12,0c-.3,0-.45.26-.45.57a.52.52,0,0,0,.28.54l.19.09c.13.05.15.09.15.31s0,.31-.19.31-.18-.1-.18-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='22.69 2.1 22.69 0.3 22.99 0.3 22.99 0.02 22.13 0.02 22.13 0.3 22.43 0.3 22.43 2.1 22.69 2.1'
        style={{ fill: '#fff' }}
      />
      <path
        d='M24.05,1.66l.08.44h.26L24,0h-.21L23.36,2.1h.26l.08-.44Zm-.18-1h0l.13.75h-.27Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='25.32 2.1 25.32 0.3 25.62 0.3 25.62 0.02 24.76 0.02 24.76 0.3 25.06 0.3 25.06 2.1 25.32 2.1'
        style={{ fill: '#fff' }}
      />
      <rect x='26.26' y='0.02' width='0.26' height='2.09' style={{ fill: '#fff' }} />
      <path
        d='M28.14,1.61V.51a.44.44,0,1,0-.87,0v1.1a.44.44,0,1,0,.87,0Zm-.61,0V.51c0-.14.08-.21.18-.21s.17.07.17.21v1.1c0,.15-.08.21-.17.21S27.53,1.76,27.53,1.61Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='29.14 0.85 29.54 2.1 29.78 2.1 29.78 0.02 29.52 0.02 29.52 1.27 29.52 1.27 29.12 0.02 28.87 0.02 28.87 2.1 29.14 2.1 29.14 0.85 29.14 0.85'
        style={{ fill: '#fff' }}
      />
      <path
        d='M30.48,1.52v.11a.46.46,0,0,0,.44.49c.34,0,.45-.26.45-.61s0-.47-.27-.57L30.9.85c-.13-.06-.15-.12-.15-.28s0-.27.18-.27.18.11.18.25V.62h.26V.47A.45.45,0,0,0,30.94,0c-.3,0-.45.26-.45.57a.51.51,0,0,0,.29.54L31,1.2c.13.05.15.09.15.31s0,.31-.19.31-.18-.1-.18-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <polygon points='33.5 0 33.3 0 32.96 2.12 33.16 2.12 33.5 0' style={{ fill: '#fff' }} />
      <path
        d='M35.08,1.52v.11a.46.46,0,0,0,.45.49c.34,0,.45-.26.45-.61s0-.47-.28-.57L35.5.85c-.13-.06-.14-.12-.14-.28s0-.27.17-.27.19.11.19.25V.62H36V.47A.46.46,0,0,0,35.54,0c-.3,0-.44.26-.44.57s0,.43.28.54l.19.09c.12.05.15.09.15.31s0,.31-.19.31-.19-.1-.19-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='37.12 2.1 37.12 0.3 37.42 0.3 37.42 0.02 36.55 0.02 36.55 0.3 36.85 0.3 36.85 2.1 37.12 2.1'
        style={{ fill: '#fff' }}
      />
      <path
        d='M38.48,1.66l.08.44h.26L38.41,0h-.22l-.4,2.08h.26l.07-.44Zm-.18-1h0l.13.75h-.26Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='39.75 2.1 39.75 0.3 40.05 0.3 40.05 0.02 39.19 0.02 39.19 0.3 39.49 0.3 39.49 2.1 39.75 2.1'
        style={{ fill: '#fff' }}
      />
      <rect x='40.68' y='0.02' width='0.26' height='2.09' style={{ fill: '#fff' }} />
      <path
        d='M42.57,1.61V.51a.44.44,0,1,0-.88,0v1.1a.44.44,0,1,0,.88,0Zm-.62,0V.51A.19.19,0,0,1,42.13.3c.1,0,.18.07.18.21v1.1c0,.15-.08.21-.18.21S42,1.76,42,1.61Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='43.57 0.85 43.96 2.1 44.21 2.1 44.21 0.02 43.95 0.02 43.95 1.27 43.94 1.27 43.55 0.02 43.3 0.02 43.3 2.1 43.56 2.1 43.56 0.85 43.57 0.85'
        style={{ fill: '#fff' }}
      />
      <path
        d='M44.91,1.52v.11a.46.46,0,0,0,.44.49c.34,0,.45-.26.45-.61s0-.47-.27-.57l-.2-.09c-.14-.06-.15-.12-.15-.28s0-.27.18-.27.18.11.18.25V.62h.26V.47A.45.45,0,0,0,45.37,0c-.3,0-.45.26-.45.57a.52.52,0,0,0,.28.54l.19.09c.13.05.15.09.15.31s0,.31-.19.31-.18-.1-.18-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <rect x='46.5' y='1.14' width='0.77' height='0.3' style={{ fill: '#fff' }} />
      <path
        d='M48,1.52v.11a.46.46,0,0,0,.45.49c.33,0,.45-.26.45-.61s0-.47-.28-.57l-.2-.09c-.13-.06-.14-.12-.14-.28s0-.27.17-.27.19.11.19.25V.62h.26V.47A.46.46,0,0,0,48.42,0c-.3,0-.45.26-.45.57a.51.51,0,0,0,.29.54l.18.09c.13.05.16.09.16.31s0,.31-.19.31-.19-.1-.19-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='50.34 0.3 50.34 0.02 49.56 0.02 49.56 2.1 50.34 2.1 50.34 1.8 49.82 1.8 49.82 1.19 50.27 1.19 50.27 0.91 49.82 0.91 49.82 0.3 50.34 0.3'
        style={{ fill: '#fff' }}
      />
      <path
        d='M51.44,0H51V2.1h.26V1.21h.16l.24.89H52l-.29-1A.55.55,0,0,0,51.9.63C51.9.23,51.75,0,51.44,0Zm0,.93h-.15V.3h.14c.19,0,.22.11.22.32S51.61,1,51.43,1Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='53.13 2.1 53.51 0.02 53.23 0.02 53.02 1.47 53.02 1.47 52.81 0.02 52.53 0.02 52.9 2.1 53.13 2.1'
        style={{ fill: '#fff' }}
      />
      <rect x='54.18' y='0.02' width='0.26' height='2.09' style={{ fill: '#fff' }} />
      <path
        d='M56,.62V.5a.44.44,0,0,0-.41-.5.44.44,0,0,0-.43.48V1.63c0,.38.23.49.43.49A.45.45,0,0,0,56,1.64V1.51h-.26v.1c0,.17-.09.21-.16.21s-.16-.09-.16-.21V.54c0-.14,0-.24.16-.24s.16.1.16.22v.1Z'
        style={{ fill: '#fff' }}
      />
      <polygon
        points='57.54 0.3 57.54 0.02 56.76 0.02 56.76 2.1 57.54 2.1 57.54 1.8 57.02 1.8 57.02 1.19 57.47 1.19 57.47 0.91 57.02 0.91 57.02 0.3 57.54 0.3'
        style={{ fill: '#fff' }}
      />
      <path
        d='M58.16,1.52v.11a.46.46,0,0,0,.45.49c.34,0,.45-.26.45-.61S59,1,58.79.94l-.2-.09c-.14-.06-.15-.12-.15-.28s0-.27.17-.27.19.11.19.25V.62h.26V.47A.46.46,0,0,0,58.62,0c-.29,0-.44.26-.44.57a.52.52,0,0,0,.28.54l.19.09c.13.05.15.09.15.31s0,.31-.19.31-.18-.1-.18-.21V1.52Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M11.66,0V11.79h1.9V5.65a3.92,3.92,0,0,1,2.23-.78c.81,0,1.22.34,1.22,1q0,1.31-2.49,1.56v.68l2.4,3.67h2.25L16.9,8.39c1.34-.63,2-1.46,2-2.51a2.42,2.42,0,0,0-2.74-2.74,5.13,5.13,0,0,0-2.61.68V0Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M19.79,5.28a7.73,7.73,0,0,1,2.45-.41c1.31,0,2,.39,2,1.16V6.6a8.47,8.47,0,0,0-1.76-.17c-2.18,0-3.27.89-3.27,2.66s1,2.7,3.05,2.7a4,4,0,0,0,2.1-.61l.82.61h1V6q0-2.85-3.87-2.85a7.73,7.73,0,0,0-2.45.41Zm4.42,4.1a3,3,0,0,1-2,.68c-.77,0-1.15-.33-1.15-1s.46-1,1.37-1a8,8,0,0,1,1.76.17Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M29,8.41h1.36a.73.73,0,0,1,.83.82c0,.61-.41.91-1.23.91A6.79,6.79,0,0,1,27,9.56v1.82a7.88,7.88,0,0,0,2.83.49C32,11.87,33,11,33,9.15s-.83-2.63-2.51-2.63H29.31c-.6,0-.9-.28-.9-.83s.51-.9,1.54-.9a6.48,6.48,0,0,1,2.73.57V3.55A7.21,7.21,0,0,0,30,3.06c-2.23,0-3.35.9-3.35,2.72S27.42,8.41,29,8.41Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M33.57,14.92h1.9V11.38a4,4,0,0,0,1.75.41c2.44,0,3.65-1.52,3.65-4.54q0-4.11-4-4.11a14.19,14.19,0,0,0-3.32.36ZM35.47,5A7.28,7.28,0,0,1,37,4.9c1.35,0,2,.78,2,2.33C39,9.06,38.4,10,37.22,10a3.43,3.43,0,0,1-1.75-.45Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M45.36,11.79a11.34,11.34,0,0,0,2.58-.25V9.81a10.5,10.5,0,0,1-2.29.25c-1.66,0-2.48-.58-2.48-1.75H48.1c.45-3.44-.58-5.17-3.08-5.17s-3.76,1.41-3.76,4.23S42.63,11.79,45.36,11.79ZM44.8,4.85c1.08,0,1.58.62,1.51,1.86H43.17C43.25,5.47,43.8,4.85,44.8,4.85Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M48.9,11.79h1.9V6a2.42,2.42,0,0,1,1.91-1.1V3.14a2.85,2.85,0,0,0-2.1,1.1l-.24-1.1H48.9Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M56.89,6.52H55.67c-.6,0-.9-.28-.9-.83s.52-.9,1.55-.9a6.55,6.55,0,0,1,2.73.57V3.55a7.25,7.25,0,0,0-2.68-.49C54.13,3.06,53,4,53,5.78s.77,2.63,2.3,2.63h1.37a.73.73,0,0,1,.82.82c0,.61-.41.91-1.23.91a6.83,6.83,0,0,1-2.9-.58v1.82a7.88,7.88,0,0,0,2.83.49c2.14,0,3.21-.91,3.21-2.72S58.57,6.52,56.89,6.52Z'
        style={{ fill: '#fff' }}
      />
      <path
        d='M.51,5.64c0,.07.14.2.2.28C1.13,4.74,3.47,4,4.16,4A3.66,3.66,0,0,0,.48,4.3a.87.87,0,0,0-.14.29l0,0A3.23,3.23,0,0,0,.16,7.07a4.24,4.24,0,0,0,2.71,2.8,1.37,1.37,0,0,1-1.46-.71c.41,1.47,2.2,2.23,4,.87A4.51,4.51,0,0,0,7.44,8.71a5.05,5.05,0,0,1-1.07.23c.11-.16.22-.33.32-.51A3.06,3.06,0,0,0,8,8.17a1,1,0,0,0,.48-.66A4.39,4.39,0,0,0,7.7,2.26,3,3,0,0,0,6.62.63a2.33,2.33,0,0,0-2.83,0,1.49,1.49,0,0,1,1.88.41.81.81,0,0,1,.06.13A4.72,4.72,0,0,0,2,1.61,4.29,4.29,0,0,0,.22,3.93a2.1,2.1,0,0,1,1.72-1,7.36,7.36,0,0,1,3.56.55c.19.08.37.16.54.25A14.48,14.48,0,0,1,5,7.58a8.44,8.44,0,0,1-2.54-.77A6.37,6.37,0,0,0,4.69,8.12a6.56,6.56,0,0,1-.35.59A6.65,6.65,0,0,1,.76,6.28l0,0C.67,6.15.57,6,.52,5.92a1.66,1.66,0,0,1-.22-1A1.65,1.65,0,0,0,.51,5.64ZM8.41,6a2.53,2.53,0,0,1,.07,1.05,1.83,1.83,0,0,0-.07-.32l0,0a2.79,2.79,0,0,1-1.2.66A10.25,10.25,0,0,0,7.83,5,2.82,2.82,0,0,1,8.39,6Z'
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};

export const Account = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13.19'>
      <path d='M6.5,6.09A2.55,2.55,0,1,0,4,3.55,2.54,2.54,0,0,0,6.5,6.09Z' style={{ fill: 'none' }} />
      <path
        d='M6.5,7.1C3.38,7.1,1,9.71,1,11a1.37,1.37,0,0,0,1.5,1.18H12A5.54,5.54,0,0,0,6.5,7.1Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M13,12.68A6.59,6.59,0,0,0,8.54,6.44a3.55,3.55,0,1,0-4.09,0C1.87,7.25,0,9.46,0,11a2.36,2.36,0,0,0,2.5,2.18H13ZM4,3.55A2.55,2.55,0,1,1,6.5,6.09,2.55,2.55,0,0,1,4,3.55ZM1,11C1,9.71,3.38,7.1,6.5,7.1A5.54,5.54,0,0,1,12,12.19H2.5A1.37,1.37,0,0,1,1,11Z'
        style={{ fill: '#e4032e' }}
      />
    </svg>
  );
};

export const Arrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.5 7'>
      <polygon points='0 0.86 0 0.86 4.51 3.5 0 6.14 0 6.14 0.5 7 6.5 3.5 0.5 0 0 0.86' style={{ fill: '#fff' }} />
    </svg>
  );
};

export const G95E10 = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
      <polygon
        points='9.64 20.05 12.4 20.05 12.4 18.98 9.64 18.98 9.64 17.7 12.76 17.7 12.76 16.59 8.28 16.59 8.28 22.53 12.87 22.53 12.87 21.43 9.64 21.43 9.64 20.05'
        style={{ fill: 'none' }}
      />
      <path
        d='M18.92,13.88a1.8,1.8,0,0,0,.82-.71,1.84,1.84,0,0,0,.27-1,1.66,1.66,0,0,0-.59-1.34,2.89,2.89,0,0,0-1.86-.5h-.48l.09-1.12h2.48V8.11H16l-.3,3.35h1.51a2.19,2.19,0,0,1,1.08.19.64.64,0,0,1,.3.58.62.62,0,0,1-.29.56,1.4,1.4,0,0,1-.82.21,3,3,0,0,1-.9-.14,2.4,2.4,0,0,1-.78-.4l-.55,1.06a3.37,3.37,0,0,0,1,.47,4.74,4.74,0,0,0,1.24.16A3.13,3.13,0,0,0,18.92,13.88Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M10.93,11.61a2.4,2.4,0,0,0,1,.23,2,2,0,0,0,1.48-.54A1.94,1.94,0,0,1,13,12.59a1.62,1.62,0,0,1-1.19.44,2,2,0,0,1-1.12-.29l-.5,1a2.72,2.72,0,0,0,.73.31,3.85,3.85,0,0,0,.91.1,3.28,3.28,0,0,0,1.59-.37,2.56,2.56,0,0,0,1.06-1.1A3.67,3.67,0,0,0,14.83,11a3.12,3.12,0,0,0-.71-2.19A2.55,2.55,0,0,0,12.17,8,2.71,2.71,0,0,0,11,8.25a2,2,0,0,0-.81.7,1.89,1.89,0,0,0-.29,1,1.75,1.75,0,0,0,.27,1A1.77,1.77,0,0,0,10.93,11.61Zm.61-2.33A1.06,1.06,0,0,1,12.26,9a1.14,1.14,0,0,1,.76.24.84.84,0,0,1,.27.64.81.81,0,0,1-.28.64,1.06,1.06,0,0,1-.73.25,1.1,1.1,0,0,1-.74-.24.83.83,0,0,1-.27-.65A.8.8,0,0,1,11.54,9.28Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='13.37 17.7 14.55 17.7 14.55 22.53 15.93 22.53 15.93 16.59 13.37 16.59 13.37 17.7'
        style={{ fill: 'none' }}
      />
      <path
        d='M20.86,16.86a2.52,2.52,0,0,0-2.63,0,2.46,2.46,0,0,0-.9,1.05A3.79,3.79,0,0,0,17,19.56a3.79,3.79,0,0,0,.33,1.65,2.46,2.46,0,0,0,.9,1.05,2.52,2.52,0,0,0,2.63,0,2.39,2.39,0,0,0,.9-1.05,3.79,3.79,0,0,0,.33-1.65,3.79,3.79,0,0,0-.33-1.65A2.39,2.39,0,0,0,20.86,16.86ZM20.4,21a1,1,0,0,1-1.7,0,2.61,2.61,0,0,1-.31-1.44,2.61,2.61,0,0,1,.31-1.44,1,1,0,0,1,1.7,0,2.7,2.7,0,0,1,.31,1.44A2.7,2.7,0,0,1,20.4,21Z'
        style={{ fill: 'none' }}
      />
      <path d='M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM10.22,9a2,2,0,0,1,.81-.7A2.71,2.71,0,0,1,12.17,8a2.55,2.55,0,0,1,1.95.76A3.12,3.12,0,0,1,14.83,11a3.67,3.67,0,0,1-.38,1.72,2.56,2.56,0,0,1-1.06,1.1,3.28,3.28,0,0,1-1.59.37,3.85,3.85,0,0,1-.91-.1,2.72,2.72,0,0,1-.73-.31l.5-1a2,2,0,0,0,1.12.29A1.62,1.62,0,0,0,13,12.59a1.94,1.94,0,0,0,.48-1.29,2,2,0,0,1-1.48.54,2.4,2.4,0,0,1-1-.23A1.77,1.77,0,0,1,10.2,11a1.75,1.75,0,0,1-.27-1A1.89,1.89,0,0,1,10.22,9Zm2.65,13.58H8.28V16.59h4.48V17.7H9.64V19H12.4v1.07H9.64v1.38h3.23Zm3.06,0H14.55V17.7H13.37V16.59h2.56Zm-.65-9,.55-1.06a2.4,2.4,0,0,0,.78.4,3,3,0,0,0,.9.14,1.4,1.4,0,0,0,.82-.21.62.62,0,0,0,.29-.56.64.64,0,0,0-.3-.58,2.19,2.19,0,0,0-1.08-.19H15.73L16,8.11h3.62V9.22H17.17l-.09,1.12h.48a2.89,2.89,0,0,1,1.86.5A1.66,1.66,0,0,1,20,12.18a1.84,1.84,0,0,1-.27,1,1.8,1.8,0,0,1-.82.71,3.13,3.13,0,0,1-1.37.27A4.74,4.74,0,0,1,16.31,14,3.37,3.37,0,0,1,15.28,13.52Zm6.48,7.69a2.39,2.39,0,0,1-.9,1.05,2.52,2.52,0,0,1-2.63,0,2.46,2.46,0,0,1-.9-1.05A3.79,3.79,0,0,1,17,19.56a3.79,3.79,0,0,1,.33-1.65,2.46,2.46,0,0,1,.9-1.05,2.52,2.52,0,0,1,2.63,0,2.39,2.39,0,0,1,.9,1.05,3.79,3.79,0,0,1,.33,1.65A3.79,3.79,0,0,1,21.76,21.21Z' />
      <path d='M19.55,17.65a1,1,0,0,0-.85.47,2.61,2.61,0,0,0-.31,1.44A2.61,2.61,0,0,0,18.7,21a1,1,0,0,0,1.7,0,2.7,2.7,0,0,0,.31-1.44,2.7,2.7,0,0,0-.31-1.44A1,1,0,0,0,19.55,17.65Z' />
      <path d='M12.28,10.81a1.06,1.06,0,0,0,.73-.25.81.81,0,0,0,.28-.64A.84.84,0,0,0,13,9.28,1.14,1.14,0,0,0,12.26,9a1.06,1.06,0,0,0-.72.24.8.8,0,0,0-.27.64.83.83,0,0,0,.27.65A1.1,1.1,0,0,0,12.28,10.81Z' />
    </svg>
  );
};

export const G98E5 = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
      <path
        d='M17.73,18.82h-.48l.09-1.12h2.48V16.59H16.2l-.31,3.34h1.52a2.12,2.12,0,0,1,1.07.2.69.69,0,0,1,0,1.14,1.37,1.37,0,0,1-.81.21,3.14,3.14,0,0,1-.91-.14,2.87,2.87,0,0,1-.78-.4l-.54,1a3.06,3.06,0,0,0,1,.47,4.68,4.68,0,0,0,1.23.17,3.1,3.1,0,0,0,1.37-.27,1.8,1.8,0,0,0,1.1-1.7,1.66,1.66,0,0,0-.6-1.34A2.86,2.86,0,0,0,17.73,18.82Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='11.66 20.05 14.41 20.05 14.41 18.98 11.66 18.98 11.66 17.7 14.78 17.7 14.78 16.59 10.29 16.59 10.29 22.53 14.89 22.53 14.89 21.43 11.66 21.43 11.66 20.05'
        style={{ fill: 'none' }}
      />
      <path
        d='M11.5,13a2,2,0,0,1-1.11-.29l-.51,1a2.79,2.79,0,0,0,.74.31,3.85,3.85,0,0,0,.91.1,3.24,3.24,0,0,0,1.58-.37,2.58,2.58,0,0,0,1.07-1.1A3.67,3.67,0,0,0,14.55,11a3.12,3.12,0,0,0-.7-2.19A2.55,2.55,0,0,0,11.9,8a2.64,2.64,0,0,0-1.14.24A2,2,0,0,0,10,9a1.82,1.82,0,0,0-.3,1,1.75,1.75,0,0,0,.27,1,1.86,1.86,0,0,0,.74.65,2.37,2.37,0,0,0,1,.23,2,2,0,0,0,1.47-.54,1.89,1.89,0,0,1-.47,1.29A1.66,1.66,0,0,1,11.5,13Zm1.23-2.47a1,1,0,0,1-.73.25,1.07,1.07,0,0,1-.73-.24A.81.81,0,0,1,11,9.92a.77.77,0,0,1,.28-.64A1,1,0,0,1,12,9a1.1,1.1,0,0,1,.76.24.81.81,0,0,1,.28.64A.82.82,0,0,1,12.73,10.56Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M19.31,10.93a1.5,1.5,0,0,0,.52-.51A1.41,1.41,0,0,0,20,9.71a1.45,1.45,0,0,0-.29-.9,1.86,1.86,0,0,0-.81-.59A3.21,3.21,0,0,0,17.71,8a3.16,3.16,0,0,0-1.19.21,1.89,1.89,0,0,0-.8.59,1.45,1.45,0,0,0-.29.9,1.41,1.41,0,0,0,.18.71,1.55,1.55,0,0,0,.51.51,1.74,1.74,0,0,0-.67.58,1.49,1.49,0,0,0-.23.82,1.51,1.51,0,0,0,.31,1,2,2,0,0,0,.87.64,3.53,3.53,0,0,0,1.31.22A3.58,3.58,0,0,0,19,13.93a2.1,2.1,0,0,0,.88-.64,1.51,1.51,0,0,0,.31-1,1.49,1.49,0,0,0-.23-.82A1.7,1.7,0,0,0,19.31,10.93ZM17.05,9.22a1,1,0,0,1,.66-.2,1.1,1.1,0,0,1,.68.2.66.66,0,0,1,.25.55.63.63,0,0,1-.25.53,1.1,1.1,0,0,1-.68.2,1.07,1.07,0,0,1-.67-.2.72.72,0,0,1,0-1.08Zm1.48,3.69a1.33,1.33,0,0,1-.82.23,1.21,1.21,0,0,1-.8-.23.79.79,0,0,1,0-1.24,1.21,1.21,0,0,1,.8-.23,1.27,1.27,0,0,1,.82.23.74.74,0,0,1,.3.62A.75.75,0,0,1,18.53,12.91Z'
        style={{ fill: 'none' }}
      />
      <path d='M17.71,11.44a1.21,1.21,0,0,0-.8.23.79.79,0,0,0,0,1.24,1.21,1.21,0,0,0,.8.23,1.33,1.33,0,0,0,.82-.23.75.75,0,0,0,.3-.62.74.74,0,0,0-.3-.62A1.27,1.27,0,0,0,17.71,11.44Z' />
      <path d='M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM11.7,11.84a2.37,2.37,0,0,1-1-.23A1.86,1.86,0,0,1,9.92,11a1.75,1.75,0,0,1-.27-1A1.82,1.82,0,0,1,10,9a2,2,0,0,1,.81-.7A2.64,2.64,0,0,1,11.9,8a2.55,2.55,0,0,1,1.95.76,3.12,3.12,0,0,1,.7,2.19,3.67,3.67,0,0,1-.37,1.72,2.58,2.58,0,0,1-1.07,1.1,3.24,3.24,0,0,1-1.58.37,3.85,3.85,0,0,1-.91-.1,2.79,2.79,0,0,1-.74-.31l.51-1A2,2,0,0,0,11.5,13a1.66,1.66,0,0,0,1.2-.44,1.89,1.89,0,0,0,.47-1.29A2,2,0,0,1,11.7,11.84Zm3.19,10.69h-4.6V16.59h4.49V17.7H11.66V19h2.76v1.07H11.66v1.38h3.23Zm5-.88a1.89,1.89,0,0,1-.83.71,3.1,3.1,0,0,1-1.37.27,4.68,4.68,0,0,1-1.23-.17,3.06,3.06,0,0,1-1-.47l.54-1a2.87,2.87,0,0,0,.78.4,3.14,3.14,0,0,0,.91.14,1.37,1.37,0,0,0,.81-.21.69.69,0,0,0,0-1.14,2.12,2.12,0,0,0-1.07-.2H15.89l.31-3.34h3.62V17.7H17.34l-.09,1.12h.48a2.86,2.86,0,0,1,1.85.5,1.66,1.66,0,0,1,.6,1.34A1.89,1.89,0,0,1,19.91,21.65Zm0-8.36a2.1,2.1,0,0,1-.88.64,3.58,3.58,0,0,1-1.32.22,3.53,3.53,0,0,1-1.31-.22,2,2,0,0,1-.87-.64,1.51,1.51,0,0,1-.31-1,1.49,1.49,0,0,1,.23-.82,1.74,1.74,0,0,1,.67-.58,1.55,1.55,0,0,1-.51-.51,1.41,1.41,0,0,1-.18-.71,1.45,1.45,0,0,1,.29-.9,1.89,1.89,0,0,1,.8-.59A3.16,3.16,0,0,1,17.71,8a3.21,3.21,0,0,1,1.2.21,1.86,1.86,0,0,1,.81.59,1.45,1.45,0,0,1,.29.9,1.41,1.41,0,0,1-.18.71,1.5,1.5,0,0,1-.52.51,1.7,1.7,0,0,1,.68.58,1.49,1.49,0,0,1,.23.82A1.51,1.51,0,0,1,19.91,13.29Z' />
      <path d='M12,9a1,1,0,0,0-.71.24.77.77,0,0,0-.28.64.81.81,0,0,0,.28.65,1.07,1.07,0,0,0,.73.24,1,1,0,0,0,.73-.25A.82.82,0,0,0,13,9.92a.81.81,0,0,0-.28-.64A1.1,1.1,0,0,0,12,9Z' />
      <path d='M17.71,10.5a1.1,1.1,0,0,0,.68-.2.63.63,0,0,0,.25-.53.66.66,0,0,0-.25-.55,1.1,1.1,0,0,0-.68-.2,1,1,0,0,0-.66.2.72.72,0,0,0,0,1.08A1.07,1.07,0,0,0,17.71,10.5Z' />
    </svg>
  );
};

export const Diesel = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
      <path
        d='M6.58,10.45a2.94,2.94,0,0,0-1.32-.28H3.19v4.56H5.26a2.94,2.94,0,0,0,1.32-.28,2.13,2.13,0,0,0,.88-.8,2.41,2.41,0,0,0,0-2.4A2.13,2.13,0,0,0,6.58,10.45Zm-.28,3a1.49,1.49,0,0,1-1.09.38h-1V11h1a1.5,1.5,0,0,1,1.09.39,1.33,1.33,0,0,1,.41,1A1.35,1.35,0,0,1,6.3,13.48Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M17.82,14.62a1.5,1.5,0,0,0,.66-.51,1.29,1.29,0,0,0,.22-.71,1.05,1.05,0,0,0-.24-.73,1.39,1.39,0,0,0-.57-.39A7.29,7.29,0,0,0,17,12a4,4,0,0,1-.74-.22.38.38,0,0,1-.25-.35.44.44,0,0,1,.21-.38,1.18,1.18,0,0,1,.66-.14,2.52,2.52,0,0,1,1.26.36l.33-.81a2.54,2.54,0,0,0-.73-.3,3.47,3.47,0,0,0-.85-.1,2.58,2.58,0,0,0-1.06.19,1.36,1.36,0,0,0-.65.51,1.22,1.22,0,0,0-.22.72,1.07,1.07,0,0,0,.24.73,1.41,1.41,0,0,0,.57.4c.22.08.51.16.86.25l.53.14a.93.93,0,0,1,.33.17.35.35,0,0,1,.13.27.42.42,0,0,1-.22.36,1.26,1.26,0,0,1-.66.13,2.54,2.54,0,0,1-.81-.13,2.4,2.4,0,0,1-.7-.34l-.36.79a2.35,2.35,0,0,0,.81.38,3.7,3.7,0,0,0,1.05.15A2.58,2.58,0,0,0,17.82,14.62Z'
        style={{ fill: 'none' }}
      />
      <path
        d='M14.24,20.12a1.42,1.42,0,0,0,.58-.53,1.44,1.44,0,0,0,.21-.77,1.31,1.31,0,0,0-.58-1.13,2.82,2.82,0,0,0-1.67-.41H9.88v5.93H13a3.08,3.08,0,0,0,1.77-.42,1.38,1.38,0,0,0,.61-1.2,1.49,1.49,0,0,0-.29-.93A1.59,1.59,0,0,0,14.24,20.12Zm-3-1.81h1.36a1.5,1.5,0,0,1,.78.17.66.66,0,0,1,0,1.05,1.41,1.41,0,0,1-.78.18H11.25ZM13.67,22a1.51,1.51,0,0,1-.82.18h-1.6V20.71h1.6c.73,0,1.09.25,1.09.74A.61.61,0,0,1,13.67,22Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='14.34 13.88 11.85 13.88 11.85 12.83 13.97 12.83 13.97 12.01 11.85 12.01 11.85 11.02 14.25 11.02 14.25 10.17 10.8 10.17 10.8 14.73 14.34 14.73 14.34 13.88'
        style={{ fill: 'none' }}
      />
      <rect x='8.63' y='10.17' width='1.06' height='4.57' style={{ fill: 'none' }} />
      <polygon
        points='25.01 10.17 23.96 10.17 23.96 14.73 27.3 14.73 27.3 13.87 25.01 13.87 25.01 10.17'
        style={{ fill: 'none' }}
      />
      <polygon
        points='20.56 12.83 22.68 12.83 22.68 12.01 20.56 12.01 20.56 11.02 22.96 11.02 22.96 10.17 19.51 10.17 19.51 14.73 23.04 14.73 23.04 13.88 20.56 13.88 20.56 12.83'
        style={{ fill: 'none' }}
      />
      <polygon
        points='15.79 19.36 17.01 19.36 17.01 18.39 18.97 18.39 16.84 23.21 18.34 23.21 20.54 18.17 20.54 17.28 15.79 17.28 15.79 19.36'
        style={{ fill: 'none' }}
      />
      <path d='M12.85,20.71h-1.6v1.47h1.6a1.51,1.51,0,0,0,.82-.18.61.61,0,0,0,.27-.55C13.94,21,13.58,20.71,12.85,20.71Z' />
      <path d='M13.39,19.53a.66.66,0,0,0,0-1.05,1.5,1.5,0,0,0-.78-.17H11.25v1.4h1.36A1.41,1.41,0,0,0,13.39,19.53Z' />
      <path d='M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM10.8,10.17h3.45V11h-2.4v1H14v.82H11.85v1.05h2.49v.85H10.8ZM7.46,13.65a2.13,2.13,0,0,1-.88.8,2.94,2.94,0,0,1-1.32.28H3.19V10.17H5.26a2.94,2.94,0,0,1,1.32.28,2.13,2.13,0,0,1,.88.8,2.41,2.41,0,0,1,0,2.4Zm2.22,1.08h-1V10.17h1Zm5,8.06a3.08,3.08,0,0,1-1.77.42H9.88V17.28h2.9a2.82,2.82,0,0,1,1.67.41A1.31,1.31,0,0,1,15,18.82a1.44,1.44,0,0,1-.21.77,1.42,1.42,0,0,1-.58.53,1.59,1.59,0,0,1,.8.54,1.49,1.49,0,0,1,.29.93A1.38,1.38,0,0,1,14.72,22.79Zm.18-8.51.36-.79a2.4,2.4,0,0,0,.7.34,2.54,2.54,0,0,0,.81.13,1.26,1.26,0,0,0,.66-.13.42.42,0,0,0,.22-.36.35.35,0,0,0-.13-.27.93.93,0,0,0-.33-.17l-.53-.14c-.35-.09-.64-.17-.86-.25a1.41,1.41,0,0,1-.57-.4,1.07,1.07,0,0,1-.24-.73,1.22,1.22,0,0,1,.22-.72,1.36,1.36,0,0,1,.65-.51,2.58,2.58,0,0,1,1.06-.19,3.47,3.47,0,0,1,.85.1,2.54,2.54,0,0,1,.73.3l-.33.81a2.52,2.52,0,0,0-1.26-.36,1.18,1.18,0,0,0-.66.14.44.44,0,0,0-.21.38.38.38,0,0,0,.25.35A4,4,0,0,0,17,12a7.29,7.29,0,0,1,.86.25,1.39,1.39,0,0,1,.57.39,1.05,1.05,0,0,1,.24.73,1.29,1.29,0,0,1-.22.71,1.5,1.5,0,0,1-.66.51,2.58,2.58,0,0,1-1.06.19,3.7,3.7,0,0,1-1.05-.15A2.35,2.35,0,0,1,14.9,14.28Zm5.64,3.89-2.2,5h-1.5L19,18.39H17v1H15.79V17.28h4.75ZM23,14.73H19.51V10.17H23V11h-2.4v1h2.12v.82H20.56v1.05H23Zm4.26,0H24V10.17H25v3.7H27.3Z' />
      <path d='M5.21,11h-1v2.83h1a1.49,1.49,0,0,0,1.09-.38,1.35,1.35,0,0,0,.41-1,1.33,1.33,0,0,0-.41-1A1.5,1.5,0,0,0,5.21,11Z' />
    </svg>
  );
};

export const LPG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45 30'>
      <path
        d='M26.83,17.47a3.49,3.49,0,0,0,1.69.4,4.58,4.58,0,0,0,1.31-.2A3.43,3.43,0,0,0,31,17.1V14.65H29.69v1.77a2.34,2.34,0,0,1-1.09.26,2,2,0,0,1-1-.25,1.76,1.76,0,0,1-.7-.68,2,2,0,0,1-.25-1,2,2,0,0,1,.25-1,1.79,1.79,0,0,1,.7-.69,2.15,2.15,0,0,1,1-.24,2.08,2.08,0,0,1,1.56.68l.9-.82A3,3,0,0,0,30,11.89a3.91,3.91,0,0,0-3.15.13,3,3,0,0,0-1.19,1.12,3.08,3.08,0,0,0-.42,1.6,3.16,3.16,0,0,0,.42,1.62A3.08,3.08,0,0,0,26.83,17.47Z'
        style={{ fill: 'none' }}
      />
      <polygon
        points='15.57 11.72 14.17 11.72 14.17 17.77 18.6 17.77 18.6 16.63 15.57 16.63 15.57 11.72'
        style={{ fill: 'none' }}
      />
      <path
        d='M23.33,15.84a2.07,2.07,0,0,0,.91-.76,2.28,2.28,0,0,0,0-2.33,2.07,2.07,0,0,0-.91-.76,3.32,3.32,0,0,0-1.39-.26H19.32v6h1.4V16.1h1.22A3.48,3.48,0,0,0,23.33,15.84ZM20.72,15v-2.1h1.14a1.48,1.48,0,0,1,1,.28,1.1,1.1,0,0,1,0,1.55,1.48,1.48,0,0,1-1,.27Z'
        style={{ fill: 'none' }}
      />
      <path d='M23.14,13.92a.94.94,0,0,0-.32-.78,1.48,1.48,0,0,0-1-.28H20.72V15h1.14a1.48,1.48,0,0,0,1-.27A.94.94,0,0,0,23.14,13.92Z' />
      <path d='M45,15h0L22.5,0,0,15,22.48,30h0ZM25.65,13.14A3,3,0,0,1,26.84,12,3.91,3.91,0,0,1,30,11.89a3,3,0,0,1,1.09.78l-.9.82a2.08,2.08,0,0,0-1.56-.68,2.15,2.15,0,0,0-1,.24,1.79,1.79,0,0,0-.7.69,2,2,0,0,0-.25,1,2,2,0,0,0,.25,1,1.76,1.76,0,0,0,.7.68,2,2,0,0,0,1,.25,2.34,2.34,0,0,0,1.09-.26V14.65H31V17.1a3.43,3.43,0,0,1-1.13.57,4.58,4.58,0,0,1-1.31.2,3.49,3.49,0,0,1-1.69-.4,3.08,3.08,0,0,1-1.18-1.11,3.16,3.16,0,0,1-.42-1.62A3.08,3.08,0,0,1,25.65,13.14Zm-7,4.63H14.17v-6h1.4v4.9h3Zm2.12,0h-1.4v-6h2.62a3.32,3.32,0,0,1,1.39.26,2.07,2.07,0,0,1,.91.76,2.28,2.28,0,0,1,0,2.33,2.07,2.07,0,0,1-.91.76,3.48,3.48,0,0,1-1.39.26H20.72Z' />
    </svg>
  );
};

export const Pump = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40.23 36.49'>
      <path
        d='M21.78,15.37H3.28V4.56h18.5Zm3.44,21.12V3.34A3.34,3.34,0,0,0,21.88,0H3.34A3.34,3.34,0,0,0,0,3.34V36.49Z'
        style={{ fill: '#e41e37' }}
      />
      <path
        d='M25.22,12.09c3.86,0,5.76,0,3,9.49S29.55,34,33.18,34s7.38-2.81,3.64-16.17S40.1,2.49,40.1,2.49'
        style={{ fill: 'none', stroke: '#e41e37', strokeMiterlimit: 10 }}
      />
      <rect width='40.23' height='36.49' style={{ fill: 'none' }} />
      <polyline
        points='36.92 4.57 38.72 6.36 38.72 10.22 35.83 10.22'
        style={{ fill: 'none', stroke: '#e41e37', strokeMiterlimit: 10 }}
      />
    </svg>
  );
};

export const AdBlue = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 50'>
      <path
        d='M49,9.74V39.21A9.76,9.76,0,0,1,39.29,49H18.2a5.76,5.76,0,0,1-5.75-5.77V9.74a7,7,0,0,1,7-7H42.08a7,7,0,0,1,7,7'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M49,9.74H48V39.21A8.76,8.76,0,0,1,39.29,48H18.2a4.69,4.69,0,0,1-3.36-1.4,4.74,4.74,0,0,1-1.39-3.37V9.74A6,6,0,0,1,14.8,5.93h0a5.94,5.94,0,0,1,4.61-2.19H42.08A5.88,5.88,0,0,1,46.29,5.5,6,6,0,0,1,48,9.74h2a8,8,0,0,0-8-8H19.41a8,8,0,0,0-6.16,2.91h0a8,8,0,0,0-1.8,5.07V43.23A6.76,6.76,0,0,0,18.2,50H39.29A10.77,10.77,0,0,0,50,39.21V9.74Z'
        style={{ fill: '#4a96d2' }}
      />
      <rect x='12.45' y='21.45' width='36.59' height='8.85' style={{ fill: '#fff' }} />
      <polygon
        points='49.04 21.45 49.04 20.45 11.45 20.45 11.45 31.3 50.04 31.3 50.04 20.45 49.04 20.45 49.04 21.45 48.04 21.45 48.04 29.3 13.45 29.3 13.45 22.45 49.04 22.45 49.04 21.45 48.04 21.45 49.04 21.45'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M20.89,1.69h-1v.87h1v-1H19.13a7.86,7.86,0,0,0-5.92,2.65l.75.66v-1h-.79v.26l.18-.19-.18-.07v.26l.18-.19-.23.24h.33a.35.35,0,0,0-.1-.24l-.23.24h.33V1.69h-.33l.23.23a.33.33,0,0,0,.1-.23h-.33l.23.23-.18-.18V2l.18-.08-.18-.18V2h7V1.74L20,1.92l.18.08V1.74L20,1.92l.23-.23h-.32a.29.29,0,0,0,.09.23l.23-.23h1.67A1.64,1.64,0,0,0,21.37.48,1.77,1.77,0,0,0,20.16,0h-7A1.73,1.73,0,0,0,12,.48a1.69,1.69,0,0,0-.52,1.21V4.18A1.69,1.69,0,0,0,12,5.39a1.73,1.73,0,0,0,1.2.48h1.25l.3-.34a5.86,5.86,0,0,1,4.41-2h2.75V1.69Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M41.44,25.84c.37,0,.58.23.64.68h-1.3c.07-.45.29-.68.66-.68m1.74.93a1.78,1.78,0,0,0-.45-1.29A1.67,1.67,0,0,0,41.45,25a1.84,1.84,0,0,0-1.34.47,1.81,1.81,0,0,0-.47,1.33,1.79,1.79,0,0,0,.48,1.33,1.93,1.93,0,0,0,1.39.47,2.15,2.15,0,0,0,1-.23,1.14,1.14,0,0,0,.56-.68l-1-.3a.48.48,0,0,1-.22.28.77.77,0,0,1-.42.1.75.75,0,0,1-.49-.15.84.84,0,0,1-.24-.48h2.37a2.06,2.06,0,0,0,0-.39m-5.51-1.68v1.83a.91.91,0,0,1-.17.6.62.62,0,0,1-.47.2.46.46,0,0,1-.36-.14.73.73,0,0,1-.12-.48v-2H35.38V27.4a1.19,1.19,0,0,0,.31.92,1.38,1.38,0,0,0,2.07-.41l0,.64h1.06V25.09Zm-3.53-1.47H33v3.9a1.2,1.2,0,0,0,.25.84,1.11,1.11,0,0,0,.81.26,1.81,1.81,0,0,0,.39-.05,1.27,1.27,0,0,0,.33-.12l.07-.73a1.12,1.12,0,0,1-.35.06.28.28,0,0,1-.25-.1.54.54,0,0,1-.07-.33Zm-4.83,4.09v-1h1a.74.74,0,0,1,.48.13.43.43,0,0,1,.16.37.45.45,0,0,1-.16.38.74.74,0,0,1-.48.13Zm0-1.86v-1h.86c.43,0,.64.17.64.5s-.21.5-.64.5Zm-1.15,2.7h2.33a2.08,2.08,0,0,0,1.24-.32,1.08,1.08,0,0,0,.42-.91,1,1,0,0,0-.27-.72,1.3,1.3,0,0,0-.78-.36h0a1.14,1.14,0,0,0,.7-.35,1,1,0,0,0,.23-.68Q32,24,30.38,24H28.16Zm-2.87-.8a.54.54,0,0,1-.48-.23,1.18,1.18,0,0,1-.17-.7,1.22,1.22,0,0,1,.17-.71.56.56,0,0,1,.48-.23.54.54,0,0,1,.45.21.94.94,0,0,1,.19.59V27a.92.92,0,0,1-.19.59.53.53,0,0,1-.45.2m.64-4.13v2.05a1.28,1.28,0,0,0-.41-.48,1.19,1.19,0,0,0-.63-.17,1.26,1.26,0,0,0-1.05.48,2,2,0,0,0-.38,1.33,2.07,2.07,0,0,0,.37,1.32,1.28,1.28,0,0,0,1.05.47,1.26,1.26,0,0,0,.68-.19,1.2,1.2,0,0,0,.45-.54l0,.66h1.06V23.62ZM20.2,26.71l.34-1.1.19-.72h0l.2.72.33,1.1Zm1.64,1.84h1.23L21.47,24H20l-1.61,4.57h1.2l.32-1h1.58Z'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const Pellets = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 54'>
      <path
        d='M49.39,23V41.52a9.76,9.76,0,0,1-9.75,9.75H22.54a9.75,9.75,0,0,1-9.75-9.75V23c0-3.22,3.49-14.11,6.29-15.89V10h23V6.47C44.94,8.14,49.39,19.35,49.39,23'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M49.39,23h-1V41.52a8.76,8.76,0,0,1-8.75,8.75H22.54a8.74,8.74,0,0,1-8.75-8.75V23a16.07,16.07,0,0,1,.56-3.35,48.17,48.17,0,0,1,2.42-7.18,24,24,0,0,1,1.53-3,5.09,5.09,0,0,1,1.32-1.54l-.54-.84h-1V11h25V6.47h-1l-.5.86a3.74,3.74,0,0,1,.88.82,18.76,18.76,0,0,1,2,3.19,45.62,45.62,0,0,1,2.76,6.54,30.28,30.28,0,0,1,.87,3,10.76,10.76,0,0,1,.31,2.1h2a16.8,16.8,0,0,0-.78-4.16A50.3,50.3,0,0,0,46.54,11a27.28,27.28,0,0,0-1.92-3.29A7.26,7.26,0,0,0,42.56,5.6l-1.51-.87V9h-21V5.28l-1.54,1a5.63,5.63,0,0,0-1.33,1.3,19.24,19.24,0,0,0-2,3.51,47.34,47.34,0,0,0-2.4,6.6c-.31,1.08-.56,2.11-.74,3A12.36,12.36,0,0,0,11.79,23V41.52A10.75,10.75,0,0,0,22.54,52.27h17.1A10.75,10.75,0,0,0,50.39,41.52V23Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M40.59,3.27H20.53A1.28,1.28,0,0,0,19.2,4.49V9.15H41.91V4.49a1.27,1.27,0,0,0-1.32-1.22'
        style={{ fill: '#fff' }}
      />
      <path
        d='M40.59,3.27v-1H20.53a2.4,2.4,0,0,0-1.62.62,2.21,2.21,0,0,0-.71,1.6v5.66H42.91V4.49a2.17,2.17,0,0,0-.71-1.6,2.38,2.38,0,0,0-1.61-.62v2a.41.41,0,0,1,.26.09l.06.13V8.15H20.2V4.49l.07-.13a.37.37,0,0,1,.26-.09H40.59Z'
        style={{ fill: '#4a96d2' }}
      />
      <rect x='12.79' y='23.72' width='36.59' height='8.85' style={{ fill: '#fff' }} />
      <polygon
        points='49.38 23.72 49.38 22.72 11.79 22.72 11.79 33.57 50.38 33.57 50.38 22.72 49.38 22.72 49.38 23.72 48.38 23.72 48.38 31.57 13.79 31.57 13.79 24.72 49.38 24.72 49.38 23.72 48.38 23.72 49.38 23.72'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M41.47,30.64a2,2,0,0,0,1.22-.31,1,1,0,0,0,.43-.82.86.86,0,0,0-.34-.71,2.35,2.35,0,0,0-1.09-.39,2.18,2.18,0,0,1-.5-.15.2.2,0,0,1-.14-.17.22.22,0,0,1,.14-.19.81.81,0,0,1,.39-.08,1.13,1.13,0,0,1,.54.12,1,1,0,0,1,.39.35l.61-.6a1.38,1.38,0,0,0-.61-.48,2.41,2.41,0,0,0-1-.17,2.24,2.24,0,0,0-.91.17,1.27,1.27,0,0,0-.57.42,1,1,0,0,0-.18.56.86.86,0,0,0,.32.7,2.56,2.56,0,0,0,1.11.42,1.65,1.65,0,0,1,.48.14c.1.05.14.1.14.17a.21.21,0,0,1-.13.18,1,1,0,0,1-.38.06,1.1,1.1,0,0,1-1-.49l-.6.66a2,2,0,0,0,1.68.61m-2.26-1a.88.88,0,0,1-.5.16.42.42,0,0,1-.46-.47V28h1v-.84h-1V26l-1.17.33v.77h-.6V28h.6v1.59a1,1,0,0,0,.33.84,1.44,1.44,0,0,0,.94.26,2,2,0,0,0,.54-.07,1.43,1.43,0,0,0,.43-.19Zm-4.94-1.78c.37,0,.58.22.64.68h-1.3c.07-.46.29-.68.66-.68m1.74.92a1.78,1.78,0,0,0-.45-1.29A1.71,1.71,0,0,0,34.28,27a1.81,1.81,0,0,0-1.33.47,1.79,1.79,0,0,0-.47,1.33A1.76,1.76,0,0,0,33,30.17a1.86,1.86,0,0,0,1.38.47,2,2,0,0,0,1-.23,1.12,1.12,0,0,0,.56-.67l-1-.31a.5.5,0,0,1-.22.29.87.87,0,0,1-.42.09.73.73,0,0,1-.48-.15.69.69,0,0,1-.24-.48H36a1.41,1.41,0,0,0,0-.39m-4.64-3.15H30.2v3.91a1.15,1.15,0,0,0,.25.83,1,1,0,0,0,.8.26,1.31,1.31,0,0,0,.39-.05,1.14,1.14,0,0,0,.33-.12l.07-.73a1.15,1.15,0,0,1-.35.07.31.31,0,0,1-.25-.1.68.68,0,0,1-.07-.33Zm-2.41,0H27.78v3.91a1.19,1.19,0,0,0,.25.83,1.06,1.06,0,0,0,.8.26,1.41,1.41,0,0,0,.4-.05,1.07,1.07,0,0,0,.32-.12l.08-.73a1.21,1.21,0,0,1-.35.07.29.29,0,0,1-.25-.1.58.58,0,0,1-.07-.33Zm-3.68,2.23c.37,0,.58.22.64.68h-1.3c.07-.46.29-.68.66-.68m1.74.92a1.78,1.78,0,0,0-.45-1.29A1.73,1.73,0,0,0,25.29,27a1.64,1.64,0,0,0-1.8,1.8A1.76,1.76,0,0,0,24,30.17a1.86,1.86,0,0,0,1.38.47,2,2,0,0,0,1-.23,1.16,1.16,0,0,0,.56-.67l-1-.31a.5.5,0,0,1-.22.29.87.87,0,0,1-.42.09.77.77,0,0,1-.49-.15.73.73,0,0,1-.23-.48H27a2,2,0,0,0,0-.39M21,28.12h-.72V26.9H21a.88.88,0,0,1,.57.15.56.56,0,0,1,.18.46.57.57,0,0,1-.18.46.88.88,0,0,1-.57.15M21.15,26h-2.1v4.57h1.19V29h.91a1.94,1.94,0,0,0,1.3-.39,1.3,1.3,0,0,0,.46-1.08,1.41,1.41,0,0,0-.46-1.13,1.94,1.94,0,0,0-1.3-.39'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const Gas = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 54'>
      <path
        d='M39.24,52.27H22.93a9.3,9.3,0,0,1-9.3-9.3V17.63a9.3,9.3,0,0,1,9.3-9.31H39.24a9.31,9.31,0,0,1,9.31,9.31V43a9.3,9.3,0,0,1-9.31,9.3'
        style={{ fill: '#4a96d2' }}
      />
      <rect x='23.33' y='4.69' width='15.51' height='3.24' style={{ fill: '#fff' }} />
      <polygon
        points='38.84 7.93 38.84 6.93 24.33 6.93 24.33 5.69 37.84 5.69 37.84 7.93 38.84 7.93 38.84 6.93 38.84 7.93 39.84 7.93 39.84 3.69 22.33 3.69 22.33 8.93 39.84 8.93 39.84 7.93 38.84 7.93'
        style={{ fill: '#4a96d2' }}
      />
      <rect x='20.12' y='2.27' width='21.93' height='2.02' style={{ fill: '#4a96d2' }} />
      <rect x='29.13' y='6.7' width='3.9' height='1.22' style={{ fill: '#fff' }} />
      <polygon
        points='33.04 7.93 33.04 6.93 30.13 6.93 30.13 6.71 29.14 6.71 29.14 7.71 32.04 7.71 32.04 7.93 33.04 7.93 33.04 6.93 33.04 7.93 34.04 7.93 34.04 6.71 34.04 5.71 28.14 5.71 28.14 8.93 34.04 8.93 34.04 7.93 33.04 7.93'
        style={{ fill: '#4a96d2' }}
      />
      <rect x='14.03' y='26.32' width='34.12' height='7.96' style={{ fill: '#fff' }} />
      <polygon
        points='48.15 34.28 48.15 33.28 15.03 33.28 15.03 27.32 47.15 27.32 47.15 34.28 48.15 34.28 48.15 33.28 48.15 34.28 49.15 34.28 49.15 25.32 13.03 25.32 13.03 35.28 49.15 35.28 49.15 34.28 48.15 34.28'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const Coffee = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 54'>
      <path d='M42.38,24.78v6.71a14.71,14.71,0,1,1-29.42,0V24.78Z' style={{ fill: '#4a96d2' }} />
      <path
        d='M42.38,24.78h-1v6.71a13.71,13.71,0,1,1-27.42,0V25.78H42.38v-1h0v-1H12v7.71a15.71,15.71,0,1,0,31.42,0V23.78h-1Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M49.21,34.71h-1a4,4,0,0,1-1.28,2.91,4.58,4.58,0,0,1-3.16,1.23,4.64,4.64,0,0,1-2-.45l-.43.9.82.58a14,14,0,0,0,2.63-8.15V29.56h-1v1a4.62,4.62,0,0,1,3.16,1.23,4,4,0,0,1,1.28,2.92h2a6,6,0,0,0-1.91-4.37,6.54,6.54,0,0,0-4.53-1.78h-1v3.17a12,12,0,0,1-2.26,7l-.69,1,1.07.52a6.75,6.75,0,0,0,2.88.64,6.55,6.55,0,0,0,4.53-1.78,6,6,0,0,0,1.91-4.36Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M21.3,14.63a4.27,4.27,0,0,0-.37.68,4.17,4.17,0,0,0-.36,1.61,3.28,3.28,0,0,0,.35,1.46,3.62,3.62,0,0,0,1.18,1.34A3.4,3.4,0,0,1,23.23,21a2.33,2.33,0,0,1,.22,1,1,1,0,0,1,0,.22v0l.3.06-.3-.07h0l.3.06-.3-.07,1.94.48a2.8,2.8,0,0,0,.08-.73,4.34,4.34,0,0,0-.4-1.8,5.26,5.26,0,0,0-1.78-2.08,1.93,1.93,0,0,1-.57-.62,1.27,1.27,0,0,1-.13-.56,2.14,2.14,0,0,1,.19-.81,1.92,1.92,0,0,1,.14-.26l0-.06h0l-.35-.24.34.25h0l-.35-.24.34.25L21.3,14.63Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M25.33,7.27a7.83,7.83,0,0,0-.76,1.24,6.7,6.7,0,0,0-.76,3,4.55,4.55,0,0,0,.7,2.48,6,6,0,0,0,2.43,2.15,6.52,6.52,0,0,1,2.86,2.73,5.32,5.32,0,0,1,.58,2.41,4.44,4.44,0,0,1,0,.62l0,.15v0h0l.57.13L30.31,22h0l.57.13L30.31,22l1.95.46a5.89,5.89,0,0,0,.12-1.25,7.31,7.31,0,0,0-.8-3.32,8.48,8.48,0,0,0-3.7-3.58,4.19,4.19,0,0,1-1.67-1.44,2.55,2.55,0,0,1-.4-1.43,4.64,4.64,0,0,1,.55-2.06,5.23,5.23,0,0,1,.39-.68l.12-.18,0,0h0l-.24-.19.24.19h0l-.24-.19.24.19L25.33,7.27Z'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const Food = () => {
  return (
    <svg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m25.89 43.68c-.89.69-2.67 1.32-5.89 1.32-7 0-7-13-12-14 0-3.3 2.72-5.39 5.17-6.6a2.67 2.67 0 0 1 2.94.39c3.41 3 7.58 13.85 9 17.88.12.33.06.42.78 1.01z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='m47 23c-4 2-10 19-10 19a7.25 7.25 0 0 1 -10 0s-6-17-10-19c1-5 6-7 15-7s14 2 15 7z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='m56 31c-5 1-5 14-12 14-3.22 0-5-.63-5.89-1.32a8.3 8.3 0 0 0 .63-.59c1.15-3.23 6.36-16.91 9.15-18.3a2.67 2.67 0 0 1 2.94-.39c2.45 1.21 5.17 3.3 5.17 6.6z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='m14.4 44.29c-5.2 1.2-7.8 5.71-10.4 5.71q-3 0-3-6a15.85 15.85 0 0 1 4-10.13 2.7 2.7 0 0 1 2.61-.87c2.18.39 3.66 7.69 6.79 11.29z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='m63 44q0 6-3 6c-2.6 0-5.2-4.51-10.4-5.71 3.17-3.65 4.59-10.89 6.79-11.29a2.71 2.71 0 0 1 2.6.91 15.85 15.85 0 0 1 4.01 10.09z'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const Sandwiches = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 54'>
      <path d='M52.14,30.51H10.47c0-3.4,5-6.16,11.11-6.16H41c6.13,0,11.1,2.76,11.1,6.16' style={{ fill: '#4a96d2' }} />
      <path
        d='M52.14,30.51h0v-1H10.47v1h1a2.94,2.94,0,0,1,.66-1.79,8,8,0,0,1,3.58-2.38,16.93,16.93,0,0,1,5.87-1H41A15.65,15.65,0,0,1,48.41,27a6.6,6.6,0,0,1,2.07,1.69,2.94,2.94,0,0,1,.66,1.79h2a5,5,0,0,0-1.07-3,9.85,9.85,0,0,0-4.47-3A18.9,18.9,0,0,0,41,23.35H21.58a17.58,17.58,0,0,0-8.35,1.93,8.63,8.63,0,0,0-2.69,2.23,4.85,4.85,0,0,0-1.07,3h0v1H53.14v-1Z'
        style={{ fill: '#4a96d2' }}
      />
      <path d='M10.47,38H52.14c0,3.4-5,6.16-11.11,6.16H21.57c-6.13,0-11.1-2.76-11.1-6.16' style={{ fill: '#4a96d2' }} />
      <path
        d='M10.47,38h0v1H52.14V38h-1a2.94,2.94,0,0,1-.66,1.79A8,8,0,0,1,46.9,42.2a16.93,16.93,0,0,1-5.87,1H21.57a15.62,15.62,0,0,1-7.37-1.68,6.6,6.6,0,0,1-2.07-1.69A2.94,2.94,0,0,1,11.47,38h-2a4.91,4.91,0,0,0,1.07,3,9.85,9.85,0,0,0,4.47,3,18.67,18.67,0,0,0,6.56,1.12H41a17.39,17.39,0,0,0,8.34-1.93A8.67,8.67,0,0,0,52.07,41a5,5,0,0,0,1.07-3h0V37H9.47v1Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M10.31,35.78l1.81-.53a22,22,0,0,1,5.93-.73,22.2,22.2,0,0,1,5.95.73,24.1,24.1,0,0,0,6.5.8,24.26,24.26,0,0,0,6.52-.8l.57-.17a21.75,21.75,0,0,1,5.94-.72,21.81,21.81,0,0,1,5.94.72l2.36.69a1,1,0,1,0,.56-1.91L50,33.16a23.71,23.71,0,0,0-6.5-.8,23.84,23.84,0,0,0-6.51.8l-.57.17a22.18,22.18,0,0,1-6,.72,22.2,22.2,0,0,1-5.94-.72,24.16,24.16,0,0,0-6.51-.81,24.09,24.09,0,0,0-6.5.81l-1.8.53a1,1,0,1,0,.56,1.92Z'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};

export const CarWash = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61.49 54'>
      <path
        d='M.15,23.46H21.94A6.08,6.08,0,0,1,28,29.54,16.69,16.69,0,0,1,24.1,40.32,22.87,22.87,0,0,1,15,45.9a16.69,16.69,0,0,1-3.77.43H.15'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M.15,24.46H21.94A5.07,5.07,0,0,1,27,29.54a15.77,15.77,0,0,1-3.68,10.14l.76.64-.73-.68a19.83,19.83,0,0,1-5.66,4.07,23,23,0,0,1-2.16.94l-.62.22-.16.06h0l.29,1-.23-1a15.31,15.31,0,0,1-3.54.41H.15v2H11.24a17.43,17.43,0,0,0,4-.46h.06A23,23,0,0,0,18.6,45.5,21.77,21.77,0,0,0,24.83,41h0l0,0A17.71,17.71,0,0,0,29,29.54a7.08,7.08,0,0,0-7.08-7.08H.15v2Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M.18,8.36H2.39c2.1,0,5.37.06,8.31.36a28.46,28.46,0,0,1,4,.64,8.57,8.57,0,0,1,1.4.45,2.51,2.51,0,0,1,.73.45A9.74,9.74,0,0,1,18.42,13a63.53,63.53,0,0,1,2.17,6.41c.3,1,.56,1.93.79,2.64a9.58,9.58,0,0,0,.32.92,3.92,3.92,0,0,0,.16.36,1.47,1.47,0,0,0,.28.4l.67-.67-.84.43a1.07,1.07,0,0,0,.17.24l.67-.67-.84.43.86-.44h-1a1,1,0,0,0,.11.44l.86-.44h0l-.77-.59a1,1,0,0,0-.2.59h1l-.77-.59.7.54-.34-.82a.91.91,0,0,0-.36.28l.7.54-.34-.82.14.35-.09-.37,0,0,.14.35-.09-.37a4.27,4.27,0,0,1-.54.06c-.91.07-2.63.1-4.67.1-3.35,0-7.62-.07-11-.15l-4.3-.1L.18,22.06l-.06,2s10.39.3,17.14.3c1.62,0,3,0,4-.06.52,0,.93-.05,1.26-.09a2.77,2.77,0,0,0,.47-.09,1.22,1.22,0,0,0,.33-.15,1.16,1.16,0,0,0,.28-.25,1.05,1.05,0,0,0,.21-.63,1,1,0,0,0-.11-.46.89.89,0,0,0-.19-.27l-.38.37.46-.27-.08-.1-.38.37.46-.27,0-.05c-.06-.13-.17-.44-.3-.83-.45-1.42-1.14-4-2-6.49-.43-1.25-.89-2.48-1.39-3.56a9.8,9.8,0,0,0-1.72-2.7A4.41,4.41,0,0,0,16.87,8a14.59,14.59,0,0,0-3.2-.88A72.84,72.84,0,0,0,2.39,6.34c-1.37,0-2.26,0-2.27,0l.06,2Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M24.52,40h-1V48a2.33,2.33,0,0,1-2.33,2.32H18.31A2.32,2.32,0,0,1,16,48V45.6H15l.22,1a17.68,17.68,0,0,0,8.6-4.76l-.71-.71.7.72c.39-.38.74-.77,1.07-1.16L24.1,40v1h.42V40h0V39h-.88l-.3.36a11.36,11.36,0,0,1-.93,1h0a15.64,15.64,0,0,1-7.63,4.23L14,44.8V48a4.31,4.31,0,0,0,4.32,4.32h2.88A4.32,4.32,0,0,0,25.52,48V39h-1Z'
        style={{ fill: '#4a96d2' }}
      />
      <path d='M15.49,31.69A4.27,4.27,0,1,1,19.75,36a4.27,4.27,0,0,1-4.26-4.26' style={{ fill: '#fff' }} />
      <path
        d='M15.49,31.69h1A3.27,3.27,0,1,1,19.75,35a3.27,3.27,0,0,1-3.26-3.26h-2a5.27,5.27,0,1,0,5.26-5.27,5.26,5.26,0,0,0-5.26,5.27Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M38.86,18.75c1,0,1.85,0,2.58.07a6.35,6.35,0,0,1,1.38.25,1.82,1.82,0,0,1,1.1.82,4.35,4.35,0,0,1,.45,2.23c0,1.93-.58,5-1.94,9.7A31.21,31.21,0,0,0,41,40.47c0,4.08,1.24,7,3.16,8.83a9.55,9.55,0,0,0,6.72,2.57,9.28,9.28,0,0,0,3.19-.52,7.49,7.49,0,0,0,4.06-3.48,15.11,15.11,0,0,0,1.59-7.47,54.25,54.25,0,0,0-2.39-14.24,38.35,38.35,0,0,1-1.63-10.33c0-5.16,1.57-8,3.05-9.64a7.87,7.87,0,0,1,2-1.62,5.74,5.74,0,0,1,.64-.31l.16-.06h0l-.16-.55.16.55h0l-.16-.55.16.55-.55-1.92a8.82,8.82,0,0,0-3.78,2.57c-1.83,2-3.58,5.43-3.57,11a40.64,40.64,0,0,0,1.7,10.87,52.41,52.41,0,0,1,2.32,13.7,16.06,16.06,0,0,1-.64,4.86,6.39,6.39,0,0,1-2.56,3.66,6.65,6.65,0,0,1-3.64.95,7.57,7.57,0,0,1-5.34-2C44.1,46.45,43,44.16,43,40.47a29.27,29.27,0,0,1,1.33-8.09c1.37-4.74,2-8,2-10.26a6.62,6.62,0,0,0-.57-3,3.76,3.76,0,0,0-1.25-1.45,5.79,5.79,0,0,0-2.62-.84,27.18,27.18,0,0,0-3.07-.11v2Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M44.08,19V18H39.84v-2.5h4.6a.94.94,0,0,1,.93.94v.26A1.3,1.3,0,0,1,44.08,18v2a3.29,3.29,0,0,0,3.29-3.3v-.26a2.94,2.94,0,0,0-2.93-2.94h-6.6V20h6.24Z'
        style={{ fill: '#4a96d2' }}
      />
      <path d='M28.72,15.29l-2.11.17a1,1,0,0,0,.16,2l2.11-.16a1,1,0,1,0-.16-2' style={{ fill: '#4a96d2' }} />
      <path
        d='M35.1,18.85l2.46-1.12a1,1,0,0,0,.07-1.79l-2.41-1.31a1,1,0,0,0-1.35.39,1,1,0,0,0,.39,1.36l.66.36-.65.3a1,1,0,0,0,.83,1.81Z'
        style={{ fill: '#4a96d2' }}
      />
      <path
        d='M30.2,11.84l-1.89-.71a1,1,0,0,0-1.29.58A1,1,0,0,0,27.6,13l1.89.71a1,1,0,0,0,1.29-.58,1,1,0,0,0-.58-1.29'
        style={{ fill: '#4a96d2' }}
      />
    </svg>
  );
};
