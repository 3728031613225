import React from 'react';
import Header from '../home/header';
import ErrorText from '../legal/errorText';
import Spacer from '../top/spacer';
import Footer from '../top/footer';

const E404 = React.memo(() => {
  return (
    <React.Fragment>
      <Header />
      <ErrorText />
      <Spacer />
      <Footer />
    </React.Fragment>
  );
});

export default E404;
