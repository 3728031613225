export const details = {
  name: 'Kaspers NV',
  address: {
    street: 'O. Huysecomlaan',
    number: 23,
    zip: 'B-3400',
    city: 'Landen',
  },
  phone: '+32 (0)11 88 00 54',
  fax: '+32 (0)11 88 68 79',
  mail: 'info@stationskaspers.be',
  vat: 'BE 0445.545.645',
};
